import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, { createContext, ReactElement, ReactNode, useEffect, useMemo } from "react";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { useOrganizationContext } from "../views/AdminPanel/Organizations/hooks/useOrganizationContext";

import { FeatureFlags } from "./featureFlags";

export interface FeatureFlagProviderProps {
  children: ReactNode;
}

export interface FeatureFlagsContextType {
  flags?: FeatureFlags;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  flags: undefined,
});

type Kind = "user";

interface LDUserContext {
  kind: Kind;
  key: string;
  email: string;
  custom: {
    organizationId: string;
  };
}

export const FeatureFlagsProvider = ({ children }: FeatureFlagProviderProps): ReactElement => {
  const ldClient = useLDClient();
  const flags = useFlags();
  const { loggedInUserId, userEmail, selectedOrganizationObjectMinimal } = useOrganizationContext();
  const organizationId = selectedOrganizationObjectMinimal?.id;

  useEffect(() => {
    if (!ldClient || !loggedInUserId || !userEmail || !organizationId) {
      return;
    }

    try {
      const context: LDUserContext = {
        kind: "user",
        key: loggedInUserId,
        email: userEmail,
        custom: {
          organizationId,
        },
      };

      InfoLogger("Identifying user with LaunchDarkly", {
        loggedInUserId,
        userEmail,
        organizationId,
      });

      void ldClient.identify(context);
    } catch (error) {
      ErrorLogger("Failed to identify user with LaunchDarkly", error);
    }
  }, [ldClient, loggedInUserId, userEmail, organizationId]);

  const contextValue = useMemo(() => ({ flags: flags as FeatureFlags }), [flags]);

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlagsContext = (): FeatureFlagsContextType => {
  const context = React.useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error("useFeatureFlagsContext must be used within a FeatureFlagsProvider");
  }

  return context;
};
