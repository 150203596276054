import "./styles/App.scss";

import { API, graphqlOperation } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

import { modules, resources } from "@rivial-security/role-utils";

import { OrganizationProvider } from "@utils/Context/OrganizationContext";
import { UIContextProvider } from "@utils/Context/UIContext";
import { ErrorLogger, InfoLogger, WarningLogger } from "@utils/EventLogger";
import { cognitoApi, CognitoApiOperation } from "@utils/Functions/cognitoApi";

import awsconfig from "../../aws-exports";
import { COGNITO_USER_POOL_ENV_URL, ENV_NAME, ENV_URL } from "../../env-config";
import { FeatureFlagsProvider } from "../../featureFlags/FeatureFlagsProvider";
import { useLicenseAgreement } from "../../hooks/views/useLicenseAgreement";
import { getOrganization_minimal } from "../../views/AdminPanel/Organizations/graphql/__organizationGQL";
import { getRolePrecedenceTypeText } from "../../views/OrganizationManager/Roles/functions/getRolePrecedenceTypeText";
import { getUser_app_init } from "../../views/OrganizationManager/Users/graphql/__userGQL";
import { useGetLogo } from "../Layout/hooks/useGetLogo";
import { MainLayout } from "../Layout/MainLayout";

import { useSentryConfig } from "./hooks/useSentryConfig";

awsconfig.oauth = {
  domain: COGNITO_USER_POOL_ENV_URL,
  scope: ["openid", "email", "profile"],
  redirectSignIn: ENV_URL, // Replace with your redirect URL
  redirectSignOut: `${ENV_URL}/`, // Replace with your sign-out URL
  responseType: "code", // or 'token', depending on your requirements
};

// Amplify init
Amplify.configure(awsconfig);

Storage.configure(awsconfig);

const AuthenticatedApp = ({ onStateChange, client }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loggedInPointOfContactId, setLoggedInPointOfContactId] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("No Organization Selected");
  const [selectedOrganizationObjectMinimal, setSelectedOrganizationObjectMinimal] = useState({});

  const [selectedHighlightColor, setSelectedHighlightColor] = useState("rgba(255,190,0,0.08)");
  const [role, setRole] = useState({});
  const [roleConfig, setRoleConfig] = useState({
    modules: {},
    resources: {},
    functions: {},
  });
  const [operationTeamID, setOperationTeamID] = useState();
  const [userCognitoGroups, setUserCognitoGroups] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [isInOperationTeamGroup, setIsInOperationTeamGroup] = useState(false);

  const { t } = useTranslation();
  /**
   * Sentry configuration
   */
  const { sentryTrace } = useSentryConfig({
    selectedOrganization,
    selectedOrganizationObjectMinimal,
    operationTeamID,
    loggedInPointOfContactId,
    loggedInUserId,
    userEmail,
  });

  // default to 20 minute timeout
  const timeoutDuration = useRef(1200);

  // set timeoutDuration based on user preferences
  useEffect(() => {
    if (preferences?.loginOptions?.timeoutDuration) {
      timeoutDuration.current = preferences.loginOptions.timeoutDuration;
    }
  }, [preferences]);

  const setTimeoutDuration = (timeout) => {
    if (timeout > 0 && typeof timeout === "number" && !isNaN(timeout)) {
      timeoutDuration.current = timeout;
    }
  };

  const [sideBar, setSideBar] = useState(null);

  const startTime = useRef(new Date());

  const [isActive, setIsActive] = useState(true);

  // Initial Render
  useEffect(() => {
    getUserGroups();

    window.addEventListener("mousemove", isUserActive, false);
    window.addEventListener("mousedown", isUserActive, false);
    window.addEventListener("keypress", isUserActive, false);
    window.addEventListener("DOMMouseScroll", isUserActive, false);
    window.addEventListener("mousewheel", isUserActive, false);
    window.addEventListener("touchmove", isUserActive, false);
    window.addEventListener("MSPointerMove", isUserActive, false);

    isUserActive();
  }, []);

  useEffect(() => {
    if (!isActive) {
      client.resetStore().then(() => {
        InfoLogger("User information was successfully reset");

        Auth.signOut()
          .then(() => {
            InfoLogger(`User: ${userEmail} was successfully logged out`);

            window.removeEventListener("mousemove", isUserActive, true);
            window.removeEventListener("mousedown", isUserActive, true);
            window.removeEventListener("keypress", isUserActive, true);
            window.removeEventListener("DOMMouseScroll", isUserActive, true);
            window.removeEventListener("mousewheel", isUserActive, true);
            window.removeEventListener("touchmove", isUserActive, true);
            window.removeEventListener("MSPointerMove", isUserActive, true);

            if (typeof onStateChange === "function") {
              onStateChange("signedOut", null);
            }

            alert("You've been signed out due to inactivity");
          })
          .catch((err) => ErrorLogger(err));
      });
    }
  }, [isActive]);

  const isUserActive = () => {
    startTime.current = new Date();
  };

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime() / 1000;
      const past = new Date(startTime.current).getTime() / 1000;

      if (now - past > timeoutDuration.current) {
        setIsActive(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (preferences?.interfaceOptions) {
      if (preferences.interfaceOptions.highlightColor) {
        setHighlightColor(preferences.interfaceOptions.highlightColor);
      } else {
        setHighlightColor("rgba(255,190,0,0.08)");
      }
    }
  }, [preferences]);

  // This gets passed to the Context Provider so that the AdminLayout child may change the selected organization
  const toggleOrganization = (event) => {
    const lastSelectedOrgID = event?.target?.value;
    if (lastSelectedOrgID) {
      cognitoApi({
        organizationID: lastSelectedOrgID,
        operation: CognitoApiOperation.SET_COGNITO_USER_ATTRIBUTES,
        input: {
          userAttributes: [
            {
              Name: "custom:selectedOrgID",
              Value: lastSelectedOrgID,
            },
          ],
        },
      }).then(() => window.location.reload());
    } else {
      WarningLogger("[App.js -> toggleOrganization] Selected organization id is null");
    }
  };

  // Same functionality as toggleOrganization, but uses an ID param directly.
  const selectOrganization = (id) => {
    if (isAdmin) {
      toggleOrganization({ target: { value: id } });
    }
  };

  // This gets passed to the Context Provider so that the AdminLayout child may change the selected color
  const setHighlightColor = (hex) => {
    setSelectedHighlightColor(hex);
  };

  const [initialRoleConfig, setInitialRoleConfig] = useState({});

  const getUserGroups = async () => {
    // Gets CurrentSession token and extrapolates the groups attribute
    await Auth.currentSession()
      .then(async (response) => {
        const groups = response?.accessToken?.payload?.["cognito:groups"] ?? [];

        const accessToken = response.accessToken.jwtToken;

        // Save the token to storage to be fetched using ApolloLink
        localStorage.setItem("accessToken", accessToken);

        setUserCognitoGroups(groups);

        const isAdmin = !!groups?.includes("Admin");
        const isDeveloper = !!groups?.includes("Developer");
        /*
          Account Types
          ------
          standard
          admin
          operationTeamMember
       */
        let accountType = response?.idToken?.payload?.["custom:accountType"] ?? "";

        // Here for legacy purposes. custom:accountType should always be filled in going forward
        if (isAdmin) accountType = "admin";
        if (isDeveloper) accountType = "dev";
        const userEmail = response?.idToken?.payload?.email ?? "";
        const customPointOfContactId = response?.idToken?.payload?.["custom:pointOfContactId"] ?? "";
        const customUserId = response?.idToken?.payload?.["custom:userID"] ?? "";
        let organizationID = response?.idToken?.payload?.["custom:organizationID"] ?? "No Organization Selected";

        // Get last selected organization id for admins and operation team members
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const selectedOrgID = currentUser?.attributes?.["custom:selectedOrgID"];
        if (selectedOrgID) {
          organizationID = selectedOrgID;
        }

        setIsAdmin(isAdmin);
        setIsDev(isDeveloper);
        setUserEmail(userEmail);
        setAccountType(accountType);
        setLoggedInPointOfContactId(customPointOfContactId);
        setLoggedInUserId(customUserId);

        setSelectedOrganization(organizationID);

        await getOrganization(organizationID);
        getLoggedInUser({ loggedInUserId: customUserId, groups, userEmail, accountType });
      })
      .catch((err) => ErrorLogger("Cannot get user groups", err));
  };

  /**
   * License Agreement Hook
   */
  const licenseAgreement = useLicenseAgreement({ loggedInUserId });

  const getLoggedInUser = ({ loggedInUserId, groups, userEmail, accountType }) => {
    if (loggedInUserId) {
      API.graphql(
        graphqlOperation(getUser_app_init, {
          id: loggedInUserId,
        }),
      )
        .then((response) => {
          /**
           * User object from the database
           */
          const user = response?.data?.getUser;

          /**
           * Check if a valid user
           */
          if (user?.id) {
            /**
             * Check if a user has accepted the license agreement
             */
            if (!user?.acceptanceDate) {
              licenseAgreement?.setModalIsOpen(true);
            }

            /**
             * Get an Operation Team id if a user is part of an Operation Team
             */
            const operationTeamID = user?.operationTeam?.id;

            /**
             * Set user preferences
             */
            const preferences = user?.preferences ? JSON.parse(user?.preferences) : {};
            setPreferences(preferences);

            /**
             * Check if the user have any linked roles
             */
            if (!Array.isArray(user?.roleLinks?.items) || user?.roleLinks?.items.length === 0) {
              WarningLogger("Error! Platform User object doesn't have a role", user);
            }

            if (!user?.roleLinks?.items[0]?.role?.roleConfig) {
              WarningLogger("Error! Platform User object doesn't have a role config", user);
            }

            const roleLocal = user?.roleLinks?.items[0]?.role ? user?.roleLinks?.items[0]?.role : {};
            let roleConfigLocal = user?.roleLinks?.items[0]?.role?.roleConfig
              ? JSON.parse(user?.roleLinks?.items[0]?.role?.roleConfig)
              : {};

            roleLocal["roleConfig"] = roleConfigLocal;

            /**
             * Check if user is part of an Operation Team Cognito Group
             */
            const isInOperationTeamCognitoGroup = groups?.some((x) => x === operationTeamID);

            /**
             * If user is part of an operation team, add Operation Panel role permissions to local role config
             */
            if (operationTeamID && isInOperationTeamCognitoGroup && accountType === "operationTeamMember") {
              /**
               * Confirmed that the user is a member of an Operation Team
               */

              setOperationTeamID(operationTeamID);

              roleConfigLocal = {
                ...roleConfigLocal,

                functions: {
                  ...roleConfigLocal.functions,
                },

                modules: {
                  ...roleConfigLocal.modules,
                  [modules.OPERATION_PANEL]: true,
                  [modules.HELP_CENTER]: true,
                  [modules.ACCOUNT_MANAGER]: true,
                },

                resources: {
                  ...roleConfigLocal.resources,
                  [resources.OPERATION_TEAM_TEMPLATE]: {
                    name: "Operation Team Templates",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: true,
                    update: true,
                    delete: true,
                  },
                  [resources.OPERATION_TEAM_ORGANIZATION]: {
                    name: "Operation Team Organizations",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: false,
                    update: false,
                    delete: false,
                  },
                  [resources.OPERATION_TEAM_USER]: {
                    name: "Operation Team Users",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: false,
                    update: false,
                    delete: false,
                  },

                  // Help Center permissions
                  [resources.HELP_ARTICLE]: {
                    name: "Help Article",
                    description: "A how-to article to aid in the use of the Platform",
                    module: modules.HELP_CENTER,
                    read: true,
                  },

                  // User Preferences permissions
                  [resources.PREFERENCES]: {
                    name: "Account Preferences",
                    description: "User Preferences such as interface settings and login options",
                    module: modules.ACCOUNT_MANAGER,
                    read: true,
                    update: true,
                  },
                },
              };
            }

            roleLocal["roleConfig"] = roleConfigLocal;

            // Setting pendo visitor and account details
            try {
              const visitor = {
                id: user?.id ?? loggedInUserId,
                name: user?.name,
                email: userEmail,
                roleId: roleLocal?.id,
                roleType: getRolePrecedenceTypeText({ precedence: roleLocal?.precedence }),
                roleName: roleLocal?.name,
                accountType,
              };

              const account = {
                id: user?.operationTeamID ?? user?.ownerGroup,
                environment: ENV_NAME,
              };

              window.pendo.initialize({
                visitor,
                account,
              });
            } catch (err) {
              ErrorLogger(`Error from App.js: Could not set pendo visitor and account details: ${JSON.stringify(err)}`);
            }

            // Setting role
            setRole(roleLocal);
            setRoleConfig(roleConfigLocal);
            setInitialRoleConfig(roleConfigLocal);
            setIsInOperationTeamGroup(isInOperationTeamCognitoGroup);
          }
        })
        .catch((err) => ErrorLogger(`Error from App.js: Could not get user object: ${JSON.stringify(err)}`));
    }
  };

  /**
   *
   * @param {string} organizationID
   * @return {object} {Promise<void>}
   */
  const getOrganization = async (organizationID) => {
    await API.graphql(
      graphqlOperation(getOrganization_minimal, {
        id: organizationID,
      }),
    )
      .then((response) => {
        if (response?.data?.getOrganization) {
          setSelectedOrganizationObjectMinimal(response.data.getOrganization);
        } else {
          ErrorLogger("Error from App.js [func getOrganization()]: Could not get organization object.");
        }
      })
      .catch((err) => ErrorLogger("Can not get organization object:", err));
  };

  const resetRoleConfig = () => setRoleConfig(initialRoleConfig);

  const { orgLogo, appLogo } = useGetLogo({
    organizationID: selectedOrganizationObjectMinimal.id,
  });

  return (
    <OrganizationProvider
      key="app_main_entry_point"
      id="app_main_entry_point"
      value={{
        isAdmin,
        isDev,
        userEmail,
        loggedInPointOfContactId,
        loggedInUserId,
        selectedOrganization,
        selectedOrganizationObjectMinimal,
        toggleOrganization,
        selectOrganization,
        setHighlightColor,
        selectedHighlightColor,
        timeoutDuration: timeoutDuration.current,
        setTimeoutDuration,
        sideBar,
        setSideBar,
        role,
        roleConfig,
        setRoleConfig,
        userCognitoGroups,
        operationTeamID,
        preferences,
        setPreferences,
        getUserGroups,
        resetRoleConfig,
        orgLogo,
        appLogo,
        accountType,
        isInOperationTeamGroup,
        sentryTrace,
      }}
    >
      <FeatureFlagsProvider>
        {licenseAgreement?.modal}
        <Switch>
          <Route
            path={"/"}
            name={t("home")}
            render={() => (
              <UIContextProvider onStateChange={onStateChange}>
                <MainLayout />
              </UIContextProvider>
            )}
          />
        </Switch>
      </FeatureFlagsProvider>
    </OrganizationProvider>
  );
};

export default AuthenticatedApp;
