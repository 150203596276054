import cx from "classnames";
import { ReactElement } from "react";

import { PlatformComponentProps } from "@components/shared/Platform";

import BlueSpinner from "../../../../assets/svg/motion/motion-spinner-blue.svg";
import GraySpinner from "../../../../assets/svg/motion/motion-spinner-gray.svg";
import styles from "../motion.module.scss";

export enum SpinnerVariant {
  BLUE = "blue",
  GRAY = "gray",
}

export interface SpinnerProps extends PlatformComponentProps {
  variant?: SpinnerVariant;
}

const Spinner = ({ className, variant = SpinnerVariant.GRAY }: SpinnerProps): ReactElement => {
  const spinner = variant === SpinnerVariant.GRAY ? <GraySpinner /> : <BlueSpinner />;

  return <div className={cx(className, styles.loading)}>{spinner}</div>;
};

export default Spinner;
