import { TourProvider } from "@reactour/tour";
import React, { ReactElement, Suspense, useCallback } from "react";

import { LayoutContextProvider } from "@application/Layout/hooks/useLayoutContext";
import { useNavigationLinks } from "@application/Layout/hooks/useNavigationLinks";
import { DrawerProvider } from "@components/Molecules/DrawerStack/DrawerProvider";
import { useUIContext } from "@utils/Context/UIContext";
import DataLoader from "@utils/LoadingComponents/DataLoader";
import Loader from "@utils/LoadingComponents/Loader";

const AstraLayout = React.lazy(() => import("./components/LayoutWrappers/Astra/AstraLayout"));

export const MainLayout = (): ReactElement => {
  const { routes } = useNavigationLinks();
  const { currentTour, setCurrentTour, setPreference } = useUIContext();

  const onCloseWelcomeTour = useCallback(async () => {
    if (currentTour) {
      await setPreference("welcomeTours", { [currentTour]: true });
      setCurrentTour(null);
    }
  }, [currentTour, setCurrentTour, setPreference]);

  return (
    <TourProvider steps={[]} scrollSmooth={true} beforeClose={onCloseWelcomeTour}>
      <DataLoader isEnoughData={true} isLoading={routes?.length < 1}>
        <DrawerProvider>
          <LayoutContextProvider>
            <Suspense fallback={<Loader />}>
              <AstraLayout />
            </Suspense>
          </LayoutContextProvider>
        </DrawerProvider>
      </DataLoader>
    </TourProvider>
  );
};
