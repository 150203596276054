import { gql } from "@graphql/types";

export const COMPLIANCE_TREND_COORDINATES = gql(`
  query complianceTrendCoordinates($controlFrameworkId: ID!, $nextToken: String, $limit: Int, $dateRange: DateRange, $sort: SortDirection) {
  controlFramework(id: $controlFrameworkId) {
    complianceTrendCoordinates(nextToken: $nextToken, limit: $limit, dateRange: $dateRange, sort: $sort) {
      nextToken
      items {
        x
        y
      }
    }
  }
}
`);
